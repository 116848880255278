import "core-js/modules/es.array.splice";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.split"; /*
                                                                                                                            * @Description:
                                                                                                                            * @Version: 1.0
                                                                                                                            * @Autor: silencc
                                                                                                                            * @Date: 2020-11-13 16:45:33
                                                                                                                            * @LastEditors: Please set LastEditors
                                                                                                                            * @LastEditTime: 2022-01-05 14:15:49
                                                                                                                            */

/**
                                                                                                                                * ruleTypes: 规则类型
                                                                                                                                * genRules： rule 生成函数
                                                                                                                                * ctx： Vue实例
                                                                                                                                */
import { genModels } from '@/plugins/widget/c-form/rules';

export var models2 = genModels(function (genRules, ctx) {return [
  {
    span: 10,
    kname: 'key',
    itemProps: {
      label: '属性名称：'
      // rules: [genRules('chinese')]
    },
    component: {
      name: 'el-input',
      props: {
        maxlength: 30,
        placeholder: '请规范填写' } } },



  {
    span: 10,
    kname: 'value',
    itemProps: {
      label: '值：' },

    component: {
      name: 'el-input',
      props: {
        maxlength: 30,
        placeholder: '请规范填写' } } },



  {
    span: 4,
    itemProps: {
      labelWidth: '0',
      style: {
        textAlign: 'center' } },


    showFn: function showFn(v) {
      var arr = v.split('.');

      return arr[1] != 0;
    },
    component: {
      name: 'el-button',
      props: {
        type: 'danger',
        text: '删除',
        size: 'mini' },

      events: {
        click: function click(e) {
          var arr = e.split('.');

          ctx.form[arr[0]].splice(arr[1], 1);
        } } } }];});




export var models = genModels(function (genRules, ctx) {return [
  {
    span: 12,
    kname: 'name',
    itemProps: {
      label: '项目名称：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 30 } } },



  {
    span: 12,
    kname: 'code',
    itemProps: {
      label: '项目编码：',
      rules: [genRules('project')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 20 } } },




  {
    span: 12,
    kname: 'price',
    itemProps: {
      label: '项目价格：',
      rules: [genRules('require'), genRules('number')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 5 } } },



  {
    span: 12,
    kname: 'categoryList',
    itemProps: {
      label: '项目分类：',
      rules: [genRules('require', '', '', 'blue')] },

    component: {
      name: 'selector',
      props: {
        type: 'categorysType',
        clearable: true,
        cascader: true,
        disableStatus: 1
        // showAllLevels: false
      } } },


  {
    kname: 'specimen',
    itemProps: {
      label: '标本要求：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },



  {
    span: 12,
    kname: 'content',
    itemProps: {
      label: '检测内容：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },



  {
    span: 12,
    kname: 'clinicalSignificance',
    itemProps: {
      label: '临床意义：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },



  {
    span: 12,
    kname: 'reportTime',
    itemProps: {
      label: '报告时间：' },

    component: {
      name: 'el-input',
      props: {
        maxlength: 20 } } },



  {
    span: 12,
    kname: 'testMethod',
    itemProps: {
      label: '检测方法：',
      rules: [genRules('require')] },

    component: {
      name: 'el-input',
      props: {
        maxlength: 50 } } },




  {
    span: 24,
    kname: 'additionalAttributes',
    type: 'list',
    models: models2(ctx) }

  // {
  //   span: 9,
  //   kname: 'li',
  //   itemProps: {
  //     label: '工作质量评价：'
  //   },
  //   type: 'list',
  //   models: models2(ctx)
  //   // component: {
  //   //   type: 'list',
  //   //   span: 24,
  //   //   models: models2(ctx)
  //   // }
  // }
  ];});

// form 默认值
export var formData = function formData() {return {
    additionalAttributes: [{}] };};