var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.visible,
        width: _vm.width
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            size: _vm.size,
            "label-width": _vm.labelWidth
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateSubmit($event)
            }
          }
        },
        [
          _c("c-form-row", {
            attrs: { list: _vm.models, readOnly: _vm.readOnly },
            scopedSlots: _vm._u([
              {
                key: "additionalAttributes",
                fn: function(item) {
                  return _c("c-box", { attrs: { flex: "row" } }, [
                    _c(
                      "div",
                      { staticClass: "grow" },
                      [
                        _c(
                          "c-box",
                          { attrs: { margin: " 10px 0 10px 30px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  disabled: _vm.readOnly
                                },
                                on: { click: _vm.pushAdditionalAttributes }
                              },
                              [_vm._v("添加属性")]
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.form[item.data.kname], function(m, idx) {
                          return _c("c-form-row", {
                            key: idx,
                            attrs: {
                              readOnly: _vm.readOnly,
                              list: item.data.models,
                              propPrefix: item.data.kname + "." + idx + "."
                            },
                            model: {
                              value: _vm.form[item.data.kname][idx],
                              callback: function($$v) {
                                _vm.$set(_vm.form[item.data.kname], idx, $$v)
                              },
                              expression: "form[item.data.kname][idx]"
                            }
                          })
                        })
                      ],
                      2
                    )
                  ])
                }
              }
            ]),
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          !_vm.readOnly
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.validateSubmit }
                },
                [_vm._v("保 存")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }